import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row, TabContent, TabPane, Nav,
  NavItem,
  NavLink, } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_CARDEX, API_COMPANY, API_CUSTOMER, API_PRODUCT, API_LOGISTIC, API_SUPPLIER } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";
import { Typeahead } from "react-bootstrap-typeahead";
import uniqid from 'uniqid';
import { random } from "lodash";
import AWS from 'aws-sdk';
import { DocumentCapture } from "../../common/hydro";

const POR_LLEGAR = 'POR_LLEGAR';
const EN_ALMACEN = 'EN_ALMACEN';
const ENVIADO = 'ENVIADO';
const FACTURACION = 'FACTURACION';
const POR_PAGAR = 'POR_PAGAR';
const PAGADO = 'PAGADO';

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_KEY_SECRET,
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
});

const Logistic = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinOrder(false); clearModal(); generateLogistcId();};
  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState("1");

  const [modalCardex, setModalCardex] = useState(false);
  const toggleCardex = () => {setModalCardex(!modalCardex);  setProducts([]); clearModalCardex(); }; 

  const clearModal = ()=> {
    setParts([]);
    setPrimarycolorLineTab("1");
    setCurrentLogistic({});
    setDocuments([]);
  }

  const clearModalCardex = () => {
    setDate(new Date());
    setnoDocument('');
    document.querySelector('#actionProducts').value = "";
  }

  const [products, setProducts] = useState([]);
  const [noDocument, setnoDocument] = useState('');

  const [cardexType, setCardexType] = useState('IN');
  const handleChangeType = (value) => {
    setCardexType(value);
  }

  const [date, setDate] = useState(new Date());
  const handleChangeStart = (date) => {
    setDate(date);
  };

  const handleProductEdited = (uuid,type,value,id) => {
    const modifiedProducts = [];

    products.map((product)=>{
      if(uuid===product.uuid){
        // if(type==="qty"){
        //   product.qty = Number(value);
        // }
        if(type==="location"){
          product.location = value;
        }
        if(type==="rango"){
          product.range = value;
        }
        if(type==="model"){
          product.range = value;
        }
        if(type==="category"){
          product.category = value;
        }
      }
      modifiedProducts.push(product);
    });
    setProducts(products);

    const temporalParts = [];
    parts.map((part)=>{
      if(type==="location"){
        if(part.id===id){
          part.location = value;
        }
      }
      temporalParts.push(part);
    });

    setParts(temporalParts);
  }

  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const handleDeleteProduct = async (item) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Descartarás la entrada del producto: ${item.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        setIsDeletingProduct(true);
        let modifiedProducts = [];
    
        products.map(product=>{
          if(product.uuid!==item.uuid){
            modifiedProducts.push(product);
          }
        });
        
        setProducts(modifiedProducts);
        setIsDeletingProduct(false);

        const temporalIds = [];
        manyIds.map((ide)=>{
          if(item.id!==ide){
            temporalIds.push(ide);
          }else{
            const checkboxes = document.querySelectorAll('#chequer-'+ide);
            checkboxes.forEach(checkbox => {
              checkbox.checked = false;
            });
          }
        });
    
        setManyIds(temporalIds);
      }
    });
  }

  const [savingCardex, setSaveCardex] = useState(false);
  const saveCardex = async () => {
    let temporalProducts = [];
    setSaveCardex(true);

    if(products.length<1){
      toast.error('El movimiento no puede ser guardado sin productos', {position: 'bottom-right', style:{color:'white'}});
      setSaveCardex(false);
      return;
    }

    if(noDocument === "" && cardexType === 'IN'){
      toast.error("Asigna un número de nota/factura de entrada", {position: 'bottom-right', style:{color:'white'}});
      setSaveCardex(false);
      return;
    }

    if(noDocument === "" && cardexType === 'OUT'){
      toast.error("Asigna un número de nota/factura de salida", {position: 'bottom-right', style:{color:'white'}});
      setSaveCardex(false);
      return;
    }

    if(date==="" || date === null){
      toast.error("Ingresa la fecha", {position: 'bottom-right', style:{color:'white'}});
      setSaveCardex(false);
      return;
    }

    let errorValidations = false;
    products.map((product)=>{
      if(product.new){
        if(product.category==="" || product.category===null || product.category===undefined){
          toast.error(product.name + " no tiene tipo", {position: 'bottom-right', style:{color:'white'}});
          errorValidations = true;
        }
        if(product.location===""){
          toast.error(product.name + " no tiene ubicación", {position: 'bottom-right', style:{color:'white'}});
          errorValidations = true;
        }
      }
    });

    if(errorValidations){
      setSaveCardex(false);
      return;
    }

    await Promise.all(products.map(async (product)=>{
      if(product.new && manyIds.includes(product.id) && cardexType === 'IN'){
        const newProduct = {
          uuid: uuidv4(),
          serie: product.name,
          model: product.model,
          range: product.range,
          location: product.location,
          category: product.category,
          qty: product.qty,
          status: "ACTIVE",
        }

        await axios.post(`${API_PRODUCT}`,newProduct).then(response => {
          if(response.data){
            product.uuid = newProduct.uuid;
            temporalProducts.push(product);
          }
        }).catch(e => {
          toast.error("No se pudo crear el producto: " + e.message, {position: 'bottom-right', style:{color:'white'}});
        });
      }else{
        temporalProducts.push(product);
      }

      setProducts(temporalProducts);
    })).then(async ()=>{

      if(cardexType==='IN'){
        let tempParts = [];
        parts.map((part)=>{
          if(manyIds.includes(part.id)){
            temporalProducts.map((product)=>{
              if(product.id===part.id){
                part.uuid = product.uuid;
              }
            })
            tempParts.push(part);
          }else{
            tempParts.push(part);
          }
        });

        setParts(tempParts);
      }

      const newCardex = {
        date: date,
        document: noDocument,
        uuid: uuidv4(),
        type: cardexType,
        products: products,
        edit: true,
      }

      await axios.post(`${API_CARDEX}`,newCardex).then(response => {
        if(response.data){
          if(cardexType === 'IN'){
            let tempParts = [];
            parts.map((part)=>{
              if(manyIds.includes(part.id)){
                part.status = EN_ALMACEN;
                part.receptionDate = new Date().toISOString().split("T")[0];
                tempParts.push(part);
              }else{
                tempParts.push(part);
              }
            });

            setParts(tempParts);
            setManyIds([]);
            toggleCardex();
            clearModalCardex();

            if(!isEditingOrder){
              saveLogistic(); 
            }else{
              patchLogistic();
              getLogistics();
            }

            toast.success("¡Entrada creada con éxito!", {position: 'bottom-right', style:{color:'white'}});
          }else{
            let temporalParts = [];
            parts.map((part)=>{
              manyIds.map((ide)=>{
                if(ide===part.id){
                  part.status = ENVIADO;
                }
              });
              temporalParts.push(part);
            })
            setParts(temporalParts);
  
            setManyIds([]);
            toggleCardex();
            clearModalCardex();
            patchLogistic();
            getLogistics();
    
            toast.success("¡Productos marcados como enviados con éxito!", {position: 'bottom-right', style:{color:'white'}});
          }
          setSaveCardex(false);
        }
      }).catch(e => {
        setSaveCardex(false);
        toast.error("No se pudo crear la entrada: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    });
  };

  const [dataSuppliers,setdataSuppliers] = useState([]);
  const getSupplierList = async () => {
    await axios.get(`${API_SUPPLIER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const suppliers = [];
        response.data.docs.map(customer=>{
          const addCustomer = {
            uuid: customer.uuid,
            name: customer.name,
          }
          suppliers.push(addCustomer);
        });
        setdataSuppliers(suppliers);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Proveedores: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataLogisticList, setDataLogistic] = useState([]);
  const [isGetPurchaseList, setIsGetPurchaseList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getLogistics = async () => {
    setIsGetPurchaseList(true);

    let queryRule = '';
    if(user.role==='ALMACEN' || user.role ==='SISTEMAS' || user.role ==='ADMIN'){
      setEditPermissions(true);
      queryRule = ``;
    }
    
    await axios.get(`${API_LOGISTIC}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataLogistic(response.data.docs);
      }else{
        setDataLogistic([]);
      }
      setIsGetPurchaseList(false);
    }).catch(e => {
      setIsGetPurchaseList(false);
      toast.error("No se pudo obtener el listado de Seguimientos: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const estado = (status) =>{
    switch(status){
      case 'ACTIVE':
        return <div><span className="badge badge-success">Activo</span></div>;
      case 'DISABLED':
        return <div><span className="badge badge-warning">Desactivado</span></div>;
    }
  }

  const columns = [
    {
      name: "ID Seguimiento",
      selector: (row) => <b>{row.logisticId}</b>,
      sortable: true,
      width: '130px'
    },
    {
      name: "Productos",
      selector: (row) => <b>{row.parts.length}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Compras",
      selector: (row) => <b>{row.parts.filter(part=>part.status === POR_LLEGAR).length}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Almacen",
      selector: (row) => <b>{row.parts.filter(part=>part.status === EN_ALMACEN).length}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Enviados",
      selector: (row) => <b>{row.parts.filter(part=>part.status === ENVIADO).length}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Facturación",
      selector: (row) => <b>{row.parts.filter(part=>part.status === FACTURACION).length}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Cobranza",
      selector: (row) => <b>{row.parts.filter(part=>part.status === POR_PAGAR).length}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Pagados",
      selector: (row) => <b>{row.parts.filter(part=>part.status === PAGADO).length}</b>,
      sortable: true,
      center: true,
    },
    // {
    //   name: "Fecha Estimada",
    //   selector: (row) => <b>{row.eta !== null && row.eta !== undefined ? row.eta.split('T')[0] : ''}</b>,
    //   sortable: true,
    //   width: '220px'
    // },
    {
      name: "Creada",
      selector: (row) => <b>{row.createdAt.split("T")[0]}</b>,
      sortable: true,
      center: true,
      width: '130px'
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                            {editPermissions?
                            <>
                              <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}><i className="fa fa-pencil"></i></Button>
                            </> : null}
                        </div>,
      width: '100px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [logisticId, setlogisticId] = useState('');
  const generateLogistcId = async () =>{
    const tempDate = new Date();

    await axios.get(`${API_COMPANY}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        response.data.docs.map(company=>{
          setlogisticId("CIE-"+(tempDate.getMonth()+1)+(tempDate.getFullYear())+"-"+company.autoLogistic);
        });
      }
    }).catch(e => {
      toast.error("No se pudo obtener el consecutivo de seguimietno: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [savingLogistic, setSaveLogistic] = useState(false);
  const saveLogistic = async () => {
    setSaveLogistic(true);

    const newLogistic = {
      uuid: uuidv4(),
      logisticId: logisticId,
      parts: parts,
    }

    await axios.post(`${API_LOGISTIC}`,newLogistic).then(response => {
      if(response.data){
        getLogistics();
        reset();
        setEditinOrder(true);
        setCurrentLogistic(response.data);
        toast.success("¡Seguimiento de productos creado con Éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setSaveLogistic(false);
    }).catch(e => {
      setSaveLogistic(false);
      toast.error("No se pudo crear el seguimiento de productos: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  };

  const [currentLogistic, setCurrentLogistic] = useState({});
  const handleEditAction = (logistic) => {
    toggle();
    setEditinOrder(true);
    setCurrentLogistic(logistic);

    if(logistic.parts !== null && logistic.parts !== undefined){
      setParts(logistic.parts);
    }

    if(logistic.files !== null && logistic.files !== undefined){
      setDocuments(logistic.files);
    }
    
    setTimeout(()=>{
      setlogisticId(logistic.logisticId);
    },500)
  }

  const [isEditingOrder, setEditinOrder] = useState(false);
  const [patchingLogistic, setPatchingLogistic] = useState(false);
  const patchLogistic = async (data) => {
    setPatchingLogistic(true);
    const dataPatch = {parts: parts};

    await axios.patch(`${API_LOGISTIC}/${currentLogistic.uuid}`,dataPatch).then(response => {
      if(response.data){
        getLogistics();
        setPatchingLogistic(false);
        toast.success("¡Seguimiento actualizado con Éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setPatchingLogistic(false);
    }).catch(e => {
      setPatchingLogistic(false);
      toast.error("No se pudo actualizar el seguimiento: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataLogisticList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [parts, setParts] = useState([]);

  const addPartNumber = () => {
    let temporalParts = [];
    const part = {
      id: uniqid() + random(1000),
      noPart: '',
      description: '',
      pediment: '',
      customer: '',
      receptionDate: '',
      aduana: '',
      qty: 0,
      invoiceSupplier: '',
      importDate: '',
      stock: 0,
      notes: '',
      purchaseOrder: '',
      purchaseOrderCiesa: '',
      carrier: '',
      guideNumber: '',
      amount: 0,
      sendDate: '',
      ciesaInvoice: '',
      dateCustomerReception: '',
      location: '',
      provider: '',
      status: POR_LLEGAR,
    }

    parts.map((value)=>{
      temporalParts.push(value);
    });
    temporalParts.push(part);

    setParts(temporalParts);
  }

  const deleteItem = (id, part) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Eliminar el producto con número de parte: ${part} es una acción irreversible`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        let temporalParts = [];
        parts.map((value)=>{
          if(value.id!==id)
            temporalParts.push(value);
        });

        setParts(temporalParts);
      }
    });
  }

  const duplicateItem = (id) => {
    parts.map((value)=>{
        if(value.id===id){
          console.log(value)
          const duplicatedPart = {
            id: uniqid() + random(1000),
            noPart: value.noPart,
            description: value.description,
            pediment: value.pediment,
            customer: '',
            receptionDate: '',
            aduana: value.aduana,
            qty: value.qty,
            invoiceSupplier: value.invoiceSupplier,
            importDate: value.importDate,
            stock: value.stock,
            notes: value.notes,
            sendDate: value.sendDate,
            ciesaInvoice: value.ciesaInvoice,
            carrier: value.carrier,
            guia: value.guia,
            amount: value.amount,
            location: value.location,
            provider: value.provider,
            status: POR_LLEGAR,
            purchaseOrderCiesa: value.purchaseOrderCiesa,
            purchaseOrder: value.purchaseOrder,
          }
          parts.push(duplicatedPart);
        }
    });

    editProduct('none','',0);
  }

  const editProduct = (type,value,id) => {
    let temporalParts = [];

    if(value===""){
      parts.map((part)=>{
        if(part.id===id){
          if(type==="noPart"){
            part.noPart = "";
          }
          if(type==="pediment"){
            part.pediment = "";
          }
          if(type==="aduana"){
            part.aduana = "";
          }
          if(type==="qty"){
            part.qty = "";
          }
          if(type==="provider"){
            part.provider = "";
          }
          if(type==="invoiceSupplier"){
            part.invoiceSupplier = "";
          }
          if(type==="importDate"){
            part.importDate = '';
          }
          if(type==="customer"){
            part.customer = "";
          }
          if(type==="notes"){
            part.notes = "";
          }
          if(type==="purchaseOrder"){
            part.purchaseOrder = "";
          }
          if(type==="purchaseOrderCiesa"){
            part.purchaseOrderCiesa = "";
          }
          if(type==="carrier"){
            part.carrier = "";
          }
          if(type==="guideNumber"){
            part.guideNumber = "";
          }
          if(type==="amount"){
            part.amount = "";
          }
          if(type==="ciesaInvoice"){
            part.ciesaInvoice = "";
          }
          if(type==="dateCustomerReception"){
            part.dateCustomerReception = "";
          }
        }
        temporalParts.push(part);
      });

      setParts(temporalParts);
    }else{
      parts.map((part)=>{
        if(part.id===id){
          if(type==="noPart"){
            part.noPart = value;
          }
          if(type==="pediment"){
            part.pediment = value;
          }
          if(type==="aduana"){
            part.aduana = value;
          }
          if(type==="qty"){
            part.qty = Number(value);
          }
          if(type==="provider"){
            part.provider = value;
          }
          if(type==="invoiceSupplier"){
            part.invoiceSupplier = value;
          }
          if(type==="importDate"){
            part.importDate = value.toISOString();
          }
          if(type==="customer"){
            part.customer = value;
          }
          if(type==="notes"){
            part.notes = value;
          }
          if(type==="purchaseOrder"){
            part.purchaseOrder = value;
          }
          if(type==="purchaseOrderCiesa"){
            part.purchaseOrderCiesa = value;
          }
          if(type==="carrier"){
            part.carrier = value;
          }
          if(type==="guideNumber"){
            part.guideNumber = value;
          }
          if(type==="amount"){
            part.amount = Number(value);
          }
          if(type==="dateCustomerReception"){
            part.dateCustomerReception = value.toISOString();
          }
          if(type==="ciesaInvoice"){
            part.ciesaInvoice = value;
          }
        }
        temporalParts.push(part);
      });

      setParts(temporalParts);
    }
  }

  const [manyIds, setManyIds] = useState([]);
  const selectingMany = (value,id) => {
    let temporalIds = [];

    if(value){
      manyIds.map((part)=>{
        temporalIds.push(part);
      });

      temporalIds.push(id);
    }else{
      manyIds.map((part)=>{
        if(part!==id){
          temporalIds.push(part);
        }
      });
    }

    setManyIds(temporalIds);
  }

  const cleanManyIds = () => {
    setManyIds([]);
    const checkboxes = document.querySelectorAll('.chequers');
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });
  }

  const applyMany = (type) => {
    if(manyIds.length < 1){
      document.querySelector('#actionProducts').value = "";
      document.querySelector('#actionWarehouse').value = "";
      document.querySelector('#actionSents').value = "";
      document.querySelector('#actionInvoices').value = "";
      document.querySelector('#actionComplete').value = "";
      alert("Selecciona al menos un producto");
      return;
    }

    if(type!==""){
      let accion = '';

      switch(type){
        case 'duplicate' :
          accion = 'duplicarán '+ manyIds.length + ' productos';
        break;
        case 'intoWarehouse':
          accion = 'dara entrada de almacen a '+ manyIds.length + ' productos';
        break;
        case 'sendProducts':
          accion = 'marcarán '+ manyIds.length + ' productos como enviados y se les dará salida en almacen';
        break;
        case 'invoiceProducts':
          accion = 'marcarán '+ manyIds.length + ' productos como recibidos por el cliente';
        break;
        case 'payProducts':
          accion = 'marcarán '+ manyIds.length + ' productos como facturados';
        break;
        case 'completeProducts':
          accion = 'marcarán '+ manyIds.length + ' productos como pagados';
        break;
      }

      SweetAlert.fire({
        title: "¿Continuamos?",
        text: `Se ${accion}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          switch(type){
            case 'duplicate' :
              manyIds.map((id)=>{
                duplicateItem(id);
              })

              setManyIds([]);
              document.querySelector('#actionProducts').value = "";
              const checkboxes = document.querySelectorAll('.chequers');
              checkboxes.forEach(checkbox => {
                checkbox.checked = false;
              });
            break;
            case 'intoWarehouse' :
              await validateInHouse();
            break;
            case 'sendProducts' :
              await validateSendProducts();
            break;
            case 'invoiceProducts' :
              await validateInvoiceProducts();
            break;
            case 'payProducts' :
              await validatePayProducts();
            break;
            case 'completeProducts' :
              await validateComplete();
            break;
          }
        }else{
          document.querySelector('#actionProducts').value = "";
          document.querySelector('#actionWarehouse').value = "";
          document.querySelector('#actionSents').value = "";
          document.querySelector('#actionInvoices').value = "";
          document.querySelector('#actionComplete').value = "";
        }
      });
    }
  }

  const validateInHouse = async () => {
    let errorCapture = false;
    let errorMessage = '';
    
    await Promise.all(parts.map((part)=>{
      manyIds.map((ide)=>{
        if(part.id===ide){
          if(part.noPart===''){
            errorMessage += 'Un elemento seleccionado no incluye número de parte - ';
            errorCapture = true;
          }
          if(part.pediment===''){
            errorMessage += `${part.noPart} no tiene Pedimento - `;
            errorCapture = true;
          }
          if(part.aduana===''){
            errorMessage += `${part.noPart} no tiene Aduana - `;
            errorCapture = true;
          }
          if(part.qty==='' || part.qty < 1){
            errorMessage += `${part.noPart} no tiene cantidad - `;
            errorCapture = true;
          }
          if(part.provider===''){
            errorMessage += `${part.noPart} no tiene Proveedor - `;
            errorCapture = true;
          }
          if(part.invoiceSupplier===''){
            errorMessage += `${part.noPart} no tiene factura de proveedor - `;
            errorCapture = true;
          }
          if(part.importDate===''){
            errorMessage += `${part.noPart} no tiene fecha de importación proveedor - `;
            errorCapture = true;
          }
          if(part.customer===''){
            errorMessage += `${part.noPart} no tiene cliente - `;
            errorCapture = true;
          }
          if(part.purchaseOrder===''){
            errorMessage += `${part.noPart} no tiene orden compra del cliente - `;
            errorCapture = true;
          }
          if(part.purchaseOrderCiesa===''){
            errorMessage += `${part.noPart} no tiene orden compra de CIESA - `;
            errorCapture = true;
          }
        }
      })
    })).then((data)=>{
      if(errorCapture){
        SweetAlert.fire({
          title: "Espera...",
          text: `No se puede generar la entrada: ${errorMessage}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        
        document.querySelector('#actionProducts').value = "";
      }else{
        setCardexType('IN');
        toggleCardex();
        let temporalProducts = [];
        parts.map((part)=>{
          manyIds.map((ide)=>{
            if(ide===part.id){
              let product = {
                id: part.id,
                uuid: part.stock === 'SIN EXISTENCIA' ? part.id : part.uuid,
                name: part.noPart,
                location: part.location,
                qty: part.qty,
                new: part.stock === 'SIN EXISTENCIA' ? true : false,
              }
              temporalProducts.push(product);
            }
          })
        })
        setProducts(temporalProducts);
      }
    })
  }

  const validateSendProducts = async () => {
    let errorCapture = false;
    let errorMessage = '';
    
    await Promise.all(parts.map((part)=>{
      manyIds.map((ide)=>{
        if(part.id===ide){
          if(part.purchaseOrder===''){
            errorMessage += `${part.noPart} no tiene Orden de Compra - `;
            errorCapture = true;
          }
          if(part.carrier===''){
            errorMessage += `${part.noPart} no tiene paquetería - `;
            errorCapture = true;
          }
          if(part.guideNumber===''){
            errorMessage += `${part.noPart} no tiene número de guía - `;
            errorCapture = true;
          }
        }
      })
    })).then((data)=>{
      if(errorCapture){
        SweetAlert.fire({
          title: "Espera...",
          text: `No se pueden marcar como enviado los productos: ${errorMessage}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        
        document.querySelector('#actionSents').value = "";
      }else{
        setCardexType('OUT');
        setnoDocument(logisticId);
        toggleCardex();

        let temporalProducts = [];
        parts.map((part)=>{
          manyIds.map((ide)=>{
            if(ide===part.id){
              let product = {
                id: part.id,
                uuid: part.uuid,
                name: part.noPart,
                location: part.location,
                qty: part.qty,
              }
              temporalProducts.push(product);
            }
          })
        })
        setProducts(temporalProducts);
      }
    })
  }

  const validateInvoiceProducts = async () => {
    let errorCapture = false;
    let errorMessage = '';
    
    await Promise.all(parts.map((part)=>{
      manyIds.map((ide)=>{
        if(part.id===ide){
          if(part.dateCustomerReception==='' || part.dateCustomerReception=== null || part.dateCustomerReception===undefined){
            errorMessage += `${part.noPart} no tiene fecha de recepción del cliente - `;
            errorCapture = true;
          }
        }
      })
    })).then((data)=>{
      if(errorCapture){
        SweetAlert.fire({
          title: "Espera...",
          text: `No se pueden marcar como enviado los productos: ${errorMessage}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        
        document.querySelector('#actionSents').value = "";
      }else{
        let temporalParts = [];
        parts.map((part)=>{
          manyIds.map((ide)=>{
            if(ide===part.id){
              part.status = FACTURACION;
            }
          });
          temporalParts.push(part);
        })
        setParts(temporalParts);

        patchLogistic();
        getLogistics();
        toast.success("¡Productos marcados como recibidos por el cliente con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
    })
  }

  const validatePayProducts = async () => {
    let errorCapture = false;
    let errorMessage = '';
    
    await Promise.all(parts.map((part)=>{
      manyIds.map((ide)=>{
        if(part.id===ide){
          if(part.amount===''){
            errorMessage += `${part.noPart} no tiene monto - `;
            errorCapture = true;
          }
          if(part.ciesaInvoice===''){
            errorMessage += `${part.noPart} no tiene factura CIESA - `;
            errorCapture = true;
          }
        }
      })
    })).then((data)=>{
      if(errorCapture){
        SweetAlert.fire({
          title: "Espera...",
          text: `No se pueden marcar como enviado los productos: ${errorMessage}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        
        document.querySelector('#actionInvoices').value = "";
      }else{
        let temporalParts = [];
        parts.map((part)=>{
          manyIds.map((ide)=>{
            if(ide===part.id){
              part.status = POR_PAGAR;
            }
          });
          temporalParts.push(part);
        })
        setParts(temporalParts);

        patchLogistic();
        getLogistics();
        toast.success("¡Productos marcados como facturados con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
    })
  }

  const validateComplete = async () => {
    let temporalParts = [];
    parts.map((part)=>{
      manyIds.map((ide)=>{
        if(ide===part.id){
          part.status = PAGADO;
        }
      });
      temporalParts.push(part);
    })
    setParts(temporalParts);

    patchLogistic();
    getLogistics();
    toast.success("¡Productos marcados como pagados con éxito!", {position: 'bottom-right', style:{color:'white'}});
  }

  const searchPartNumber = async (value, id) => {
    await axios.get(`${API_PRODUCT}?status=ACTIVE&serie=${value}`).then(response => {
      let temporalParts = [];
      if(response.data.docs.length>0){
        parts.map((part)=>{
          if(part.id===id){
            part.uuid = response.data.docs[0].uuid;
            part.stock = response.data.docs[0].qty;
          }
          temporalParts.push(part);
        })
      }else{
        parts.map((part)=>{
          if(part.id===id){
            part.stock = 'SIN EXISTENCIA';
          }
          temporalParts.push(part);
        })
      }
      setParts(temporalParts);
    }).catch(e => {
      toast.error("No se pudo revisar el stock del producto: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataCustomers,setdataCustomers] = useState([]);
  const getCustomersList = async () => {
    await axios.get(`${API_CUSTOMER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const customers = [];
        response.data.docs.map(customer=>{
          const addCustomer = {
            uuid: customer.uuid,
            name: customer.name,
          }
          customers.push(addCustomer);
        });
        setdataCustomers(customers);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Servicios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [documents, setDocuments] = useState([]);
  const [attachingDocument, setAttachingDocument] = useState(false);
  const attachDocument = async (document) => {
    setAttachingDocument(true);

    await axios.patch(`${API_LOGISTIC}/${currentLogistic.uuid}`,{newDocument:document[0]}).then(response => {
      if(response.data){
        getLogistics();
        setDocuments(response.data.files)
        setAttachingDocument(false);
        toast.success("¡Archivo agregado con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setAttachingDocument(false);
    }).catch(e => {
      setAttachingDocument(false);
      toast.error("No se pudo agregar el Archivo al seguimiento: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const handleDeleteActionDocument = (file) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará el archivo adjunto.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const deleteParams = {
          Bucket: S3_BUCKET,
          Key: `posts/${file.split('/posts/')[1]}`
        }
        myBucket.deleteObject(deleteParams).send();
        const newDocuments = documents.filter((item)=>item!==file);

        await axios.patch(`${API_LOGISTIC}/${currentLogistic.uuid}`,{files: newDocuments}).then(response => {
          if(response.data){
            setDocuments(newDocuments);
            getLogistics();
          }
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el adjunto: " + error
            );
          }, 200);
        });
      }
    });
  }

  const [canUserEdit, setCanUserEdit] = useState(false);

  const canUserEditCheck = (role) => {
    switch(role){
      case 'ADMIN':
      case 'ADMIN':
      case 'ADMIN':

      break;
    }
  }

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "COMPRAS"
      && user.role !== "GERENTE"
      && user.role !== "ALMACEN"
    ){
      navigate('/inicio');
    }
    canUserEditCheck();
    getLogistics();
    getSupplierList();
    getCustomersList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Compras" title="Recepción y Lógistica"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Nuevo Seguimiento</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  paginationRowsPerPageOptions={[50,100,200,500]}
                  noDataComponent={<b style={{padding:10}}>No se encontraron documentos</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'75%'}}>
        <ModalHeader toggle={toggle}>{isEditingOrder ? 'Editar Seguimiento' : 'Crear Nuevo Seguimiento' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="container" >
          <h5>Resumen</h5>
            <div className="container mt-3 mb-3 border p-2">
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    ID de Seguimiento
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {logisticId}
                  </Label>
                </Col>
              </Row>
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Productos completados
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {parts.filter(part=>part.status === PAGADO).length} / {parts.length}
                  </Label>
                </Col>
              </Row>
              {isEditingOrder ?
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Adjuntos:
                  </Label>
                </Col>
                <Col md="6">
                  <DocumentCapture attach={attachDocument}/>
                  <div className="row">
                    {
                      documents.map((file,i)=>{
                        return(
                          <div key={i} className="flex justify-start mr-5 col-3" style={{width:170, background:'#444', borderRadius:5, padding:5, margin:7}}>
                            <a href={file} target="_blank" style={{color:'white'}}>{file.split("*.*")[1]}</a>
                            <div className="text-center" style={{background:'red', color:'white', width:30, height:30, paddingRight:10, paddingLeft:10, paddingTop:3, marginLeft:-15, borderRadius:50, cursor:'pointer', top:0, right: -10, position:'absolute'}} onClick={()=>handleDeleteActionDocument(file)}><b>x</b></div>
                          </div>
                        )
                      })
                    }
                  </div>
                </Col>
              </Row> : null}
            </div>
            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingOrder ? patchLogistic : saveLogistic)} style={{padding:20}}>
              <Nav className="nav-primary" tabs>
                <NavItem>
                  <NavLink
                    style={{cursor:'pointer'}}
                    className={PrimarycolorLineTab === "1" ? "active" : ""}
                    onClick={() => {setPrimarycolorLineTab("1"); cleanManyIds();}}
                  >
                    <i className="icofont icofont-list"></i>Compras ({parts.filter(part=>part.status === POR_LLEGAR).length})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{cursor:'pointer'}}
                    className={PrimarycolorLineTab === "2" ? "active" : ""}
                    onClick={() => {setPrimarycolorLineTab("2"); cleanManyIds();}}
                  >
                    <i className="icofont icofont-box"></i>Almacen ({parts.filter(part=>part.status === EN_ALMACEN).length})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{cursor:'pointer'}}
                    className={PrimarycolorLineTab === "3" ? "active" : ""}
                    onClick={() => {setPrimarycolorLineTab("3"); cleanManyIds();}}
                  >
                    <i className="icofont icofont-vehicle-delivery-van"></i>Envios ({parts.filter(part=>part.status === ENVIADO).length})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{cursor:'pointer'}}
                    className={PrimarycolorLineTab === "4" ? "active" : ""}
                    onClick={() => {setPrimarycolorLineTab("4"); cleanManyIds();}}
                  >
                    <i className="icofont icofont-calculator-alt-1"></i>Facturación ({parts.filter(part=>part.status === FACTURACION).length})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{cursor:'pointer'}}
                    className={PrimarycolorLineTab === "5" ? "active" : ""}
                    onClick={() => {setPrimarycolorLineTab("5"); cleanManyIds();}}
                  >
                    <i className="icofont icofont-pay"></i>Cobranza ({parts.filter(part=>part.status === PAGADO).length + parts.filter(part=>part.status === POR_PAGAR).length})
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={PrimarycolorLineTab}>
                <TabPane className="fade show" tabId="1">
                  {parts.length > 0 ?
                    parts.map(part=>{
                      if(part.status===POR_LLEGAR){
                        return(
                          <Row key={part.id} className="g-3 dflex mb-3 p-2 mt-1" style={{backgroundColor:'#f1f1f1'}}>
                            <div style={{color:'white', background:'#b4b4b4', position:'absolute', left:55, marginTop:-15, padding:"4px 0 0 0", borderRadius:5, width:30, textAlign:'center', cursor:'pointer', zIndex:1}}>
                            <input className="chequers" type="checkbox" id={"chequer-"+part.id} onClick={e=>selectingMany(e.target.checked,part.id)} style={{width:20, height:20, cursor:'pointer'}} /></div>
                            <div style={{color:'white', background:'orange', position:'absolute', right:90, marginTop:-15, padding:5, borderRadius:5, width:70, textAlign:'center', cursor:'pointer', zIndex:1}} onClick={()=>duplicateItem(part.id)}>Duplicar</div>
                            <div style={{color:'white', background:'red', position:'absolute', right:60, marginTop:-15, padding:5, borderRadius:5, width:20, textAlign:'center', cursor:'pointer', zIndex:1}} onClick={()=>deleteItem(part.id, part.noPart)}>X</div>
                            <Row md="12" className="flex d-flex g-2 mt-4">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Parte:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="No. Parte" value={part.noPart} onBlur={e=>searchPartNumber(e.target.value, part.id)} onChange={(e)=>{editProduct('noPart',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Pedimento:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Pedimento" value={part.pediment} onChange={(e)=>{editProduct('pediment',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Aduana:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Aduana" value={part.aduana} onChange={(e)=>{editProduct('aduana',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Recepción:</span>
                                  <span style={{width:170}}>{part.receptionDate}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cantidad:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="number" placeholder="Cantidad" value={part.qty} onChange={(e)=>{editProduct('qty',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>En Stock:</span>
                                  <span>{part.stock}</span>
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Proveedor:</span>
                                  <Typeahead
                                    id={"provider"+part.id}
                                    clearButton
                                    defaultSelected={part.provider}
                                    labelKey="name"
                                    options={dataSuppliers}
                                    placeholder="Buscar Proveedor..."
                                    onChange={(e)=>{editProduct('provider',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Factura Proovedor:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Factura Proovedor" value={part.invoiceSupplier} onChange={(e)=>{editProduct('invoiceSupplier',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Importación Proovedor:</span>
                                  <DatePicker className="form-control digits"
                                    onChange={(e)=>{editProduct('importDate',e, part.id);}}
                                    locale={es}
                                    dateFormat={'dd/MM/yyyy'}
                                    selected={part.importDate !== undefined && part.importDate !== null && part.importDate !== "" ? new Date(part.importDate) : part.importDate}
                                  />
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cliente:</span>
                                  <Typeahead
                                    id={"customers"+part.id}
                                    clearButton
                                    defaultSelected={part.customer}
                                    labelKey="name"
                                    options={dataCustomers}
                                    placeholder="Buscar Cliente..."
                                    onChange={(e)=>{editProduct('customer',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Status:</span>
                                  { part.status === POR_LLEGAR ?
                                    <span className="badge badge-dark">{part.status}</span>
                                  : null }
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>O. Compra CIESA:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Orden de compra CIESA" value={part.purchaseOrderCiesa} onChange={(e)=>{editProduct('purchaseOrderCiesa',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>O. Compra Cliente:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Orden de compra" value={part.purchaseOrder} onChange={(e)=>{editProduct('purchaseOrder',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Notas:</span>
                                  <textarea className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Notas" value={part.notes} onChange={(e)=>{editProduct('notes',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                            </Row>
                          </Row>
                        )
                      }
                    })
                  : null }
                  <div className="flex d-flex">
                    <Button onClick={addPartNumber} className="mt-2 btn-dark"><i className="fa fa-plus"></i> Producto</Button>
                    <select className="form-control" id="actionProducts" style={{maxHeight:40, maxWidth:250, marginTop:10, marginLeft:20}} onChange={e=>applyMany(e.target.value)}>
                      <option value={''}>-- Acciones --</option>
                      <option value={'duplicate'}>Duplicar</option>
                      <option value={'intoWarehouse'}>Crear entrada de Almacen</option>
                    </select>
                  </div>
                </TabPane>
                <TabPane className="fade show" tabId="2">
                  {parts.length > 0 ?
                    parts.map(part=>{
                      if(part.status===EN_ALMACEN){
                        return(
                          <Row key={part.id} className="g-3 dflex mb-3 p-2 mt-1" style={{backgroundColor:'#f1f1f1'}}>
                            <div style={{color:'white', background:'#b4b4b4', position:'absolute', left:55, marginTop:-15, padding:"4px 0 0 0", borderRadius:5, width:30, textAlign:'center', cursor:'pointer', zIndex:1}}>
                            <input className="chequers" type="checkbox" id={"chequer-"+part.id} onClick={e=>selectingMany(e.target.checked,part.id)} style={{width:20, height:20, cursor:'pointer'}} /></div>
                            <Row md="12" className="flex d-flex g-2 mt-4">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Parte:</span>
                                  {part.noPart}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Pedimento:</span>
                                  {part.pediment}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Aduana:</span>
                                  {part.aduana}
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Recepción:</span>
                                  <span style={{width:170}}>{part.receptionDate}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cantidad:</span>
                                  {part.qty}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>En Stock:</span>
                                  <span>{part.stock}</span>
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Proveedor:</span>
                                  <Typeahead
                                    id={"provider"+part.id}
                                    clearButton
                                    disabled
                                    defaultSelected={part.provider}
                                    labelKey="name"
                                    options={dataSuppliers}
                                    placeholder="Buscar Proveedor..."
                                    onChange={(e)=>{editProduct('provider',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Factura Proovedor:</span>
                                  {part.invoiceSupplier}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Importación Proovedor: </span>
                                  <span style={{width:150, marginLeft:5}}>{part.importDate !== null && part.importDate !== undefined && part.importDate !== '' ? part.importDate.split("T")[0] : ''}</span>
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cliente:</span>
                                  <Typeahead
                                    id={"customers"+part.id}
                                    clearButton
                                    disabled={true}
                                    defaultSelected={part.customer}
                                    labelKey="name"
                                    options={dataCustomers}
                                    placeholder="Buscar Cliente..."
                                    onChange={(e)=>{editProduct('customer',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Status:</span>
                                  { part.status === EN_ALMACEN ?
                                    <span className="badge badge-info">{part.status}</span>
                                  : null }
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>O. Compra CIESA:</span>
                                  {part.purchaseOrderCiesa}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>O. Compra Cliente:</span>
                                  {part.purchaseOrder}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Paqueteria:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Paquetería" value={part.carrier} onChange={(e)=>{editProduct('carrier',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Guía:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Número de Guía" value={part.guideNumber} onChange={(e)=>{editProduct('guideNumber',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Notas:</span>
                                  <textarea className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Notas" value={part.notes} onChange={(e)=>{editProduct('notes',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                            </Row>
                          </Row>
                        )
                      }
                    })
                  : null }
                  <div className="flex d-flex">
                    <select className="form-control" id="actionWarehouse" style={{maxHeight:40, maxWidth:250, marginTop:10, marginLeft:20}} onChange={e=>applyMany(e.target.value)}>
                      <option value={''}>-- Acciones --</option>
                      <option value={'sendProducts'}>Marcar como Enviados y dar salida de almacen</option>
                    </select>
                  </div>
                </TabPane>
                <TabPane className="fade show" tabId="3">
                  {parts.length > 0 ?
                    parts.map(part=>{
                      if(part.status===ENVIADO){
                        return(
                          <Row key={part.id} className="g-3 dflex mb-3 p-2 mt-1" style={{backgroundColor:'#f1f1f1'}}>
                            <div style={{color:'white', background:'#b4b4b4', position:'absolute', left:55, marginTop:-15, padding:"4px 0 0 0", borderRadius:5, width:30, textAlign:'center', cursor:'pointer', zIndex:1}}>
                            <input className="chequers" type="checkbox" id={"chequer-"+part.id} onClick={e=>selectingMany(e.target.checked,part.id)} style={{width:20, height:20, cursor:'pointer'}} /></div>
                            <Row md="12" className="flex d-flex g-2 mt-4">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Parte:</span>
                                  {part.noPart}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Pedimento:</span>
                                  {part.pediment}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Aduana:</span>
                                  {part.aduana}
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Recepción:</span>
                                  <span style={{width:170}}>{part.receptionDate}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cantidad:</span>
                                  {part.qty}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Proveedor:</span>
                                  <Typeahead
                                    id={"provider"+part.id}
                                    clearButton
                                    disabled
                                    defaultSelected={part.provider}
                                    labelKey="name"
                                    options={dataSuppliers}
                                    placeholder="Buscar Proveedor..."
                                    onChange={(e)=>{editProduct('provider',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Factura Proovedor:</span>
                                  {part.invoiceSupplier}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Importación Proovedor: </span>
                                  <span style={{width:150, marginLeft:5}}>{part.importDate !== null && part.importDate !== undefined && part.importDate !== '' ? part.importDate.split("T")[0] : ''}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cliente:</span>
                                  <Typeahead
                                    id={"customers"+part.id}
                                    clearButton
                                    disabled={true}
                                    defaultSelected={part.customer}
                                    labelKey="name"
                                    options={dataCustomers}
                                    placeholder="Buscar Cliente..."
                                    onChange={(e)=>{editProduct('customer',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Status:</span>
                                  { part.status === ENVIADO ?
                                    <span className="badge badge-secondary" style={{background:'purple'}}>{part.status}</span>
                                  : null }
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>O. Compra Cliente:</span>
                                  {part.purchaseOrder}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Paqueteria:</span>
                                  {part.carrier}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Guía:</span>
                                  {part.guideNumber}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha de Recepción cliente:</span>
                                  <DatePicker className="form-control digits"
                                    onChange={(e)=>{editProduct('dateCustomerReception',e, part.id);}}
                                    locale={es}
                                    dateFormat={'dd/MM/yyyy'}
                                    selected={part.dateCustomerReception !== undefined && part.dateCustomerReception !== null && part.dateCustomerReception !== "" ? new Date(part.dateCustomerReception) : part.dateCustomerReception}
                                  />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Notas:</span>
                                  <textarea className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Notas" value={part.notes} onChange={(e)=>{editProduct('notes',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              {/* <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Monto:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="number" placeholder="Monto" value={part.amount} onChange={(e)=>{editProduct('amount',e.target.value, part.id);}} />
                                </Label>
                              </Col> */}
                            </Row>
                          </Row>
                        )
                      }
                    })
                  : null }
                  <div className="flex d-flex">
                    <select className="form-control" id="actionSents" style={{maxHeight:40, maxWidth:250, marginTop:10, marginLeft:20}} onChange={e=>applyMany(e.target.value)}>
                      <option value={''}>-- Acciones --</option>
                      <option value={'invoiceProducts'}>Marcar recibidos por el cliente</option>
                    </select>
                  </div>
                </TabPane>
                <TabPane className="fade show" tabId="4">
                  {parts.length > 0 ?
                    parts.map(part=>{
                      if(part.status===FACTURACION){
                        return(
                          <Row key={part.id} className="g-3 dflex mb-3 p-2 mt-1" style={{backgroundColor:'#f1f1f1'}}>
                            <div style={{color:'white', background:'#b4b4b4', position:'absolute', left:55, marginTop:-15, padding:"4px 0 0 0", borderRadius:5, width:30, textAlign:'center', cursor:'pointer', zIndex:1}}>
                            <input className="chequers" type="checkbox" id={"chequer-"+part.id} onClick={e=>selectingMany(e.target.checked,part.id)} style={{width:20, height:20, cursor:'pointer'}} /></div>
                            <Row md="12" className="flex d-flex g-2 mt-4">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Parte:</span>
                                  {part.noPart}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Pedimento:</span>
                                  {part.pediment}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Aduana:</span>
                                  {part.aduana}
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Recepción:</span>
                                  <span style={{width:170}}>{part.receptionDate}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cantidad:</span>
                                  {part.qty}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Proveedor:</span>
                                  <Typeahead
                                    id={"provider"+part.id}
                                    clearButton
                                    disabled
                                    defaultSelected={part.provider}
                                    labelKey="name"
                                    options={dataSuppliers}
                                    placeholder="Buscar Proveedor..."
                                    onChange={(e)=>{editProduct('provider',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Factura Proovedor:</span>
                                  {part.invoiceSupplier}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Importación Proovedor: </span>
                                  <span style={{width:150, marginLeft:5}}>{part.importDate !== null && part.importDate !== undefined && part.importDate !== '' ? part.importDate.split("T")[0] : ''}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cliente:</span>
                                  <Typeahead
                                    id={"customers"+part.id}
                                    clearButton
                                    disabled={true}
                                    defaultSelected={part.customer}
                                    labelKey="name"
                                    options={dataCustomers}
                                    placeholder="Buscar Cliente..."
                                    onChange={(e)=>{editProduct('customer',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Status:</span>
                                  { part.status === FACTURACION ?
                                    <span className="badge badge-secondary" style={{background:'orange'}}>{part.status}</span>
                                  : null }
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>O. Compra Cliente:</span>
                                  {part.purchaseOrder}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Paqueteria:</span>
                                  {part.carrier}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Guía:</span>
                                  {part.guideNumber}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha de Recepción cliente:</span>
                                  <span style={{width:150, marginLeft:5}}>{part.dateCustomerReception !== null && part.dateCustomerReception !== undefined && part.dateCustomerReception !== '' ? part.dateCustomerReception.split("T")[0] : ''}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Factura CIESA:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Factura CIESA" value={part.ciesaInvoice} onChange={(e)=>{editProduct('ciesaInvoice',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Monto:</span>
                                  <input className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="number" placeholder="Monto" value={part.amount} onChange={(e)=>{editProduct('amount',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Notas:</span>
                                  <textarea className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Notas" value={part.notes} onChange={(e)=>{editProduct('notes',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                            </Row>
                          </Row>
                        )
                      }
                    })
                  : null }
                  <div className="flex d-flex">
                    <select className="form-control" id="actionInvoices" style={{maxHeight:40, maxWidth:250, marginTop:10, marginLeft:20}} onChange={e=>applyMany(e.target.value)}>
                      <option value={''}>-- Acciones --</option>
                      <option value={'payProducts'}>Marcar como facturados</option>
                    </select>
                  </div>
                </TabPane>
                <TabPane className="fade show" tabId="5">
                  {parts.length > 0 ?
                    parts.map(part=>{
                      if(part.status===POR_PAGAR || part.status===PAGADO){
                        return(
                          <Row key={part.id} className="g-3 dflex mb-3 p-2 mt-1" style={{backgroundColor:'#f1f1f1'}}>
                            <div style={{color:'white', background:'#b4b4b4', position:'absolute', left:55, marginTop:-15, padding:"4px 0 0 0", borderRadius:5, width:30, textAlign:'center', cursor:'pointer', zIndex:1}}>
                            <input className="chequers" type="checkbox" id={"chequer-"+part.id} onClick={e=>selectingMany(e.target.checked,part.id)} style={{width:20, height:20, cursor:'pointer'}} /></div>
                            <Row md="12" className="flex d-flex g-2 mt-4">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Parte:</span>
                                  {part.noPart}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Pedimento:</span>
                                  {part.pediment}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Aduana:</span>
                                  {part.aduana}
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Recepción:</span>
                                  <span style={{width:170}}>{part.receptionDate}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cantidad:</span>
                                  {part.qty}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Proveedor:</span>
                                  <Typeahead
                                    id={"provider"+part.id}
                                    clearButton
                                    disabled
                                    defaultSelected={part.provider}
                                    labelKey="name"
                                    options={dataSuppliers}
                                    placeholder="Buscar Proveedor..."
                                    onChange={(e)=>{editProduct('provider',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Factura Proovedor:</span>
                                  {part.invoiceSupplier}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha Importación Proovedor: </span>
                                  <span style={{width:150, marginLeft:5}}>{part.importDate !== null && part.importDate !== undefined && part.importDate !== '' ? part.importDate.split("T")[0] : ''}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Cliente:</span>
                                  <Typeahead
                                    id={"customers"+part.id}
                                    clearButton
                                    disabled={true}
                                    defaultSelected={part.customer}
                                    labelKey="name"
                                    options={dataCustomers}
                                    placeholder="Buscar Cliente..."
                                    onChange={(e)=>{editProduct('customer',e, part.id)}}
                                    style={{width:170, marginTop:-10, marginLeft:7}}
                                  />
                                </Label>
                              </Col>
                            </Row>
                            <Row md="12" className="flex d-flex g-2">
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Status:</span>
                                  { part.status === POR_PAGAR ?
                                    <span className="badge badge-danger">{part.status}</span>
                                  : null }
                                  { part.status === PAGADO ?
                                    <span className="badge badge-success">{part.status}</span>
                                  : null }
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>O. Compra Cliente:</span>
                                  {part.purchaseOrder}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Paqueteria:</span>
                                  {part.carrier}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>No. Guía:</span>
                                  {part.guideNumber}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Fecha de Recepción cliente:</span>
                                  <span style={{width:150, marginLeft:5}}>{part.dateCustomerReception !== null && part.dateCustomerReception !== undefined && part.dateCustomerReception !== '' ? part.dateCustomerReception.split("T")[0] : ''}</span>
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Factura CIESA:</span>
                                  {part.ciesaInvoice}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Monto:</span>
                                  {part.amount}
                                </Label>
                              </Col>
                              <Col md="4">
                                <Label className="flex d-flex form-label font-weight-bold">
                                  <span style={{width:120}}>Notas:</span>
                                  <textarea className="form-control" style={{width:170, marginTop:-10, marginLeft:7}} type="text" placeholder="Notas" value={part.notes} onChange={(e)=>{editProduct('notes',e.target.value, part.id);}} />
                                </Label>
                              </Col>
                            </Row>
                          </Row>
                        )
                      }
                    })
                  : null }
                  <div className="flex d-flex">
                    <select className="form-control" id="actionComplete" style={{maxHeight:40, maxWidth:250, marginTop:10, marginLeft:20}} onChange={e=>applyMany(e.target.value)}>
                      <option value={''}>-- Acciones --</option>
                      <option value={'completeProducts'}>Marcar como pagados</option>
                    </select>
                  </div>
                </TabPane>
              </TabContent>

              <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                {
                  isEditingOrder ? 
                    <Button disabled={patchingLogistic} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                  : 
                    <Button disabled={savingLogistic} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                }
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalCardex} toggle={toggleCardex} size="lg" style={{minWidth:'55%'}}>
        <ModalHeader toggle={toggleCardex}>{'Crear Nuevo Movimiento'}
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggleCardex}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(saveCardex)} style={{padding:20}}>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="status">
                    Tipo de Movimiento
                  </Label>
                  <select disabled defaultValue={cardexType} onChange={(e)=>handleChangeType(e.target.value)} className="form-control" name="type" >
                    <option value="IN">ENTRADA</option>
                    <option value="OUT">SALIDA</option>
                  </select>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="cardexNumber">
                    No. Nota / Factura / Referencia
                  </Label>
                  <input className="form-control" id="cardexNumber" type="text" placeholder="No. Documento" name="document" value={noDocument} onChange={(e)=>setnoDocument(e.target.value)}/>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Fecha
                  </Label>
                  <DatePicker className="form-control digits"
                      selected={date}
                      onChange={handleChangeStart}
                      selectsStart
                      locale={es}
                      maxDate={new Date()}
                      dateFormat={'dd/MM/yyyy'}
                    />
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Productos
                    <br/>
                      <span style={{color:'gray', fontSize:12}}>(Para agregar varias ubicaciones separalas con coma ",")<br/>(Los productos marcados como "Nuevo" se darán de alta en el inventario)</span>
                  </Label>
                </Col>
              </Row>
              {
                products.length>0?
                  products.map((product,index)=>{
                    return(
                      <Row className="mb-4" key={index}>
                        <Col md="12" style={{background:'#e4e4e4', padding:8, paddingTop:10, borderRadius:10, fontSize:'1rem'}}>
                          {cardexType === 'IN' ?
                            product.new ? 
                              <>
                                <Row className="dflex">
                                  <div style={{position:'absolute', left:10, background:'#777', padding:5, zIndex:1, width:50, color:'white', borderRadius:5, top:-10, fontSize:11}}>Nuevo</div>
                                  <Col sm="12" md="4" style={{padding:2, marginTop:10}}>
                                    <Label className="form-label">
                                      {product.name}
                                    </Label>
                                  </Col> 
                                  <Col sm="12" md="4" style={{padding:2, marginTop:10}}>
                                    <Label className="form-label">
                                      <input id={`input-location-${product.uuid}`} className="form-control" type="text" style={{width:'90%'}} placeholder="Rango" onChange={(e)=>handleProductEdited(product.uuid,'range',e.target.value,product.id)}/>
                                    </Label>
                                  </Col>
                                  <Col sm="12" md="4" style={{padding:2, marginTop:10}}>
                                    <Label className="form-label">
                                      <input id={`input-location-${product.uuid}`} className="form-control" type="text" style={{width:'90%'}} placeholder="Modelo" onChange={(e)=>handleProductEdited(product.uuid,'model',e.target.value,product.id)}/>
                                    </Label>
                                  </Col>
                                </Row>
                                <Row className="dflex">
                                  <Col sm="12" md="4" style={{padding:2, marginTop:0}}>
                                    <Label className="form-label flex dflex">
                                      <span style={{width:40}}>Tipo:</span> <select onChange={(e)=>handleProductEdited(product.uuid,'category',e.target.value,product.id)} style={{maxWidth:150}} className="form-control" name="category" >
                                        <option value="">--SELECCIONA--</option>
                                        <option value="REFACCIONES">Refacciones</option>
                                        <option value="MANOMETROS">Manometros</option>
                                        <option value="TERMOMETROS">Termometros</option>
                                        <option value="VALVULAS">Válvulas</option>
                                      </select>
                                    </Label>
                                  </Col>
                                  <Col sm="12" md="4" style={{padding:2}}>
                                    <input id={`input-location-${product.uuid}`} className="form-control" type="text" style={{width:'90%'}} placeholder="Ubicaciónes" onChange={(e)=>handleProductEdited(product.uuid,'location',e.target.value,product.id)}/>
                                  </Col>
                                  <Col sm="12" md="4" style={{padding:2, paddingTop:10}}>
                                    Cantidad: {product.qty}
                                  </Col>
                                </Row>
                              </>
                            : 
                              <>
                                <Row className="dflex">
                                  <Col sm="12" md="4" style={{padding:2, marginTop:10}}>
                                    <Label className="form-label">
                                      {product.name}
                                    </Label>
                                  </Col>
                                  <Col sm="12" md="4" style={{padding:2}}>
                                    <input id={`input-location-${product.uuid}`} className="form-control" type="text" style={{width:'90%'}} placeholder="Ubicaciónes" onChange={(e)=>handleProductEdited(product.uuid,'location',e.target.value,product.id)}/>
                                  </Col>
                                  <Col sm="12" md="4" style={{padding:2, paddingTop:10}}>
                                    Cantidad: {product.qty}
                                  </Col>
                                </Row>
                              </>
                          :
                            <>
                              <Row className="dflex">
                                <Col sm="12" md="4" style={{padding:2, marginTop:10}}>
                                  <Label className="form-label">
                                    {product.name}
                                  </Label>
                                </Col>
                                <Col sm="12" md="4" style={{padding:2}}>
                                  Ubicación: {product.location}
                                </Col>
                                <Col sm="12" md="4" style={{padding:2, paddingTop:10}}>
                                  Cantidad: {product.qty}
                                </Col>
                              </Row>
                            </>
                          }
                          <div style={{position:'absolute', background: 'red', color: 'white', borderRadius:50, padding:5, textAlign: 'center', fontWeight:'bold', width:25, height:25, right:-3, zIndex:100, top:18, fontSize:11, cursor:'pointer'}} onClick={()=>handleDeleteProduct(product)}>X</div>
                        </Col>
                      </Row>
                    )
                  })
                : null
              }
              <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                <Button disabled={savingCardex} className="btn btn-primary" style={{width:150}} color="primary">{"Crear Movimiento"}</Button>
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default Logistic;