import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_HOSPITAL, API_ITEM, API_PRODUCT, API_SURGERIE, API_USER } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { Typeahead } from "react-bootstrap-typeahead";
import AWS from 'aws-sdk';

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";
import { HydroCapture } from "../../common/hydro";

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_KEY_SECRET,
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET},
  region: REGION,
});

const Inventory = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();
  
  const [modal, setModal] = useState(false);
  const [modalPart, setModalPart] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinProduct(false); setcategorySelected("REFACCIONES"); setPictures([]); setItemSelected([]);};

  const togglePart = () => {setModalPart(!modalPart); setKeyItem(''); setDescriptionItem('');};

  const [categorySelected, setcategorySelected] = useState("REFACCIONES");

  const [pictures, setPictures] = useState([]);
  const [attaching, setAttaching] = useState(false);
  const attach = async (pic) => {
    setAttaching(true);

    await axios.patch(`${API_PRODUCT}/${currentProduct.uuid}`,{pictures:pic}).then(response => {
      if(response.data){
        getProductList();
        setPictures(response.data.pictures)
        setAttaching(false);
        toast.success("¡Foto agregada con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setAttaching(false);
    }).catch(e => {
      setAttaching(false);
      toast.error("No se pudo agregar la foto al servicio: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [itemSelected,setItemSelected] = useState([]);
  const [dataItems,setdataItems] = useState([]);
  const getItemList = async () => {
    await axios.get(`${API_ITEM}?type=PART`).then(response => {
      if(response.data.docs.length>0){
        const items = [];
        response.data.docs.map(item=>{
          const addItem = {
            name: item.key,
          }
          items.push(addItem);
        });
        setdataItems(items);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el catalogo de partes: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const setSerie = (value) => {
    if(value.length>0){
      setValue('serie', value[0].name);
    }else{
      setValue('serie', '');
    }
  }

  const [dataProductList, setDataProductList] = useState([]);
  const [isGetProductList, setIsGetProductList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getProductList = async () => {
    setIsGetProductList(true);

    let queryRule = '';
    if(user.role==='ALMACEN' || user.role ==='SISTEMAS' || user.role ==='ADMIN'){
      setEditPermissions(true);
      queryRule = `status=ACTIVE,DISABLED`;
    }
    
    await axios.get(`${API_PRODUCT}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataProductList(response.data.docs);
      }else{
        setDataProductList([]);
      }
      setIsGetProductList(false);
    }).catch(e => {
      setIsGetProductList(false);
      toast.error("No se pudo obtener el listado de Productos: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const columns = [
    {
      name: "",
      selector: (row) => <div style={{padding:5}}>
                            {editPermissions?
                            <>
                              <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}><i className="fa fa-pencil"></i></Button>                                  
                              {/* <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleStatusProduct(row, row.status === 'DISABLED' ? 'ACTIVE' : 'DISABLED')}}><i className="fa fa-power-off"></i></Button> */}
                            </> : null}
                        </div>,
      width: '80px',
      wrap: true,
    },
    {
      name: "Categorí­a",
      selector: (row) => <b>{row.category}</b>,
      sortable: true,
      width: '170px'
    },
    {
      name: "No. Parte",
      selector: (row) => <b>{row.serie}</b>,
      sortable: true,
      width: '170px',
      wrap: true,
    },
    {
      name: "Modelo",
      selector: (row) => <b>{row.productModel}</b>,
      sortable: true,
      width: '220px',
      wrap: true,
    },
    {
      name: "Descripción",
      selector: (row) => <b>{row.description}</b>,
      sortable: true,
      width: '220px',
      wrap: true,
    },
    {
      name: "Marca",
      selector: (row) => row.brand,
      sortable: true,
      width: '150px',
      wrap: true,
    },
    {
      name: "Rango",
      selector: (row) => row.range,
      sortable: true,
      width: '170px',
      wrap: true,
    },
    {
      name: "Existencia",
      selector: (row) => <b>{row.qty}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Ubicación",
      selector: (row) => <b>{row.locations ? row.locations.map((location)=>{return(<>{location}, </>)}) : ''}</b>,
      sortable: true,
      width: '170px',
      wrap: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [savingProduct, setSaveProduct] = useState(false);
  const saveProduct = async (data) => {
    setSaveProduct(true);
    if (data !== "") {
      data.uuid = uuidv4();
      data.status = 'ACTIVE';
      data.category = categorySelected;

      await axios.post(`${API_PRODUCT}`,data).then(response => {
        if(response.data){
          toggle();
          getProductList();
          reset();
          toast.success("¡Producto creado con Éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveProduct(false);
      }).catch(e => {
        setSaveProduct(false);
        toast.error("No se pudo crear el producto: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentProduct, setCurrentProduct] = useState({});
  const handleEditAction = (product) => {
    toggle();
    setEditinProduct(true);
    setCurrentProduct(product);
    setcategorySelected(product.category);
    setItemSelected([{name:product.serie ? product.serie : ''}]);
    
    setTimeout(()=>{
      setValue('serie', product.serie);
      setValue('productModel', product.productModel);
      setValue('description', product.description);
      setValue('brand', product.brand);
      setValue('range', product.range);
      setValue('pediment', product.pediment);
    },500)
  }

  const [isEditingProduct, setEditinProduct] = useState(false);
  const [patchingProduct, setPatchingProduct] = useState(false);
  const patchProduct = async (data) => {
    setPatchingProduct(true);
    data.category = categorySelected;

    if (data !== "") {
      await axios.patch(`${API_PRODUCT}/${currentProduct.uuid}`,data).then(response => {
        if(response.data){
          getProductList();
          toggle();
          setPatchingProduct(false);
          toast.success("¡Producto actualizado con Éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingProduct(false);
      }).catch(e => {
        setPatchingProduct(false);
        toast.error("No se pudo actualizar el producto: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleStatusProduct = (product,status) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se ${status === 'DISABLED' ? 'desactivará' : 'activará'} el producto: ${product.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.patch(`${API_PRODUCT}/${product.uuid}`,{status:status}).then(response => {
          getProductList();
          SweetAlert.fire("¡Listo!", `producto ${status === 'DISABLED' ? 'desactivado' : 'activado'}`, "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el producto: " + error
            );
          }, 200);
        });
      }
    });
  }

  const filteredItems = dataProductList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleDeleteActionPicture = (file) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la foto del producto.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const deleteParams = {
          Bucket: S3_BUCKET,
          Key: `posts/${file.split('/posts/')[1]}`
        }
        myBucket.deleteObject(deleteParams).send();
        const newPictures = pictures.filter((item)=>item!==file);

        await axios.patch(`${API_PRODUCT}/${currentProduct.uuid}`,{pictures: newPictures}).then(response => {
          if(response.data){
            setPictures(newPictures);
            getProductList();
          }
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar la imagen: " + error
            );
          }, 200);
        });
      }
    });
  }

  const [typeItem, setTypeItem] = useState('PART');
  const [keyItem, setKeyItem] = useState('');
  const [descriptionItem, setDescriptionItem] = useState('');

  const [savingItem, setSaveItem] = useState(false);
  const saveItem = async () => {
    setSaveItem(true);

    if(keyItem === ""){
      toast.warning("Ingresa una clave para el número de parte", {position: 'bottom-right', style:{color:'white'}});
      setSaveItem(false);
      return;
    }

    if(descriptionItem === ""){
      toast.warning("Ingresa una descripción para el número de parte", {position: 'bottom-right', style:{color:'white'}});
      setSaveItem(false);
      return;
    }

    const newItem = {
      uuid: uuidv4(),
      status: 'ACTIVE',
      key: keyItem,
      type: 'PART',
      price: 0,
      description: descriptionItem,
    }

    await axios.post(`${API_ITEM}`,newItem).then(response => {
      if(response.data){
        togglePart();
        getItemList();
        setValue('serie', keyItem);
        setValue('description', descriptionItem);
        setItemSelected([{name:keyItem}]);
        toast.success("¡Número de parte creado con Éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setSaveItem(false);
    }).catch(e => {
      setSaveItem(false);
      toast.error("No se pudo crear el número de parte: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  };

  const [loadingExport, setLoadingExport] = useState(false);
  
  const exportItems = async () => {
    setLoadingExport(true);

    axios.get(`${API_PRODUCT}/multiple/download`).then(response => {


      setLoadingExport(false);
    }).catch(e => {
      toast.error(e.response.data.error ? e.response.data.error : e.message,{autoClose: false});
      setLoadingExport(false);
    });
  }

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE_OPERACIONES"
      && user.role !== "ALMACEN"
      && user.role !== "COTIZADOR"
    ){
      navigate('/inicio');
    }
    getProductList();
    getItemList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Almacen" title="Inventario"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            {user.role === 'ADMIN' || user.role === 'SISTEMAS' ?
            <Button color="white" style={{marginLeft: 10, marginTop: 10}}><a href={`${process.env.REACT_APP_MS_API_URL}/product/multiple/download`}><i className="fa fa-download"></i> Descargar Inventario</a></Button> : null }
            <Button color="primary" onClick={toggle}>Crear Producto</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  paginationRowsPerPageOptions={[50,100,200,500]}
                  noDataComponent={<b style={{padding:10}}>No se encontraron productos</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{isEditingProduct ? 'Editar Producto' : 'Crear Nuevo Producto' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingProduct ? patchProduct : saveProduct)} style={{padding:20}}>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="categoryProd">
                    Categorí­a
                  </Label>
                  <select defaultValue={categorySelected} onChange={(e)=>setcategorySelected(e.target.value)} className="form-control" name="category" >
                    <option value="REFACCIONES">Refacciones</option>
                    <option value="MANOMETROS">Manometros</option>
                    <option value="TERMOMETROS">Termometros</option>
                    <option value="VALVULAS">Valvulas</option>
                  </select>
                </Col>
                {categorySelected === "REFACCIONES" ?
                  <div onClick={togglePart} style={{color:'blue', cursor:'pointer'}}>Agregar Número de Parte</div>
                : null }
              </Row>
              {categorySelected === "REFACCIONES" ?
              <>
              <Row className="g-3 dflex mb-3" hidden>
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="serie">
                    Numero de Parte
                  </Label>
                  <input className="form-control" id="serie" type="text" placeholder="No. Parte" name="serie" {...register("serie")} />
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Numero de Parte
                  </Label>
                  <Typeahead
                    id="items"
                    clearButton
                    defaultSelected={itemSelected}
                    labelKey="name"
                    options={dataItems}
                    placeholder="Buscar Numero de Parte..."
                    onChange={(e)=>{setItemSelected(e);setSerie(e)}}
                  />
                  <span>{errors.serie && "No. Parte Válido es requerido"}</span>
                </Col>
              </Row>
              </>
              : null }
              {categorySelected !== "REFACCIONES" ?
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="productModelIn">
                      Modelo
                    </Label>
                    <input className="form-control" id="productModelIn" type="text" placeholder="Modelo" name="productModel" {...register("productModel")} />
                  </Col>
                </Row>
              : null}
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="descriptTx">
                    Descripción
                  </Label>
                  <textarea className="form-control" id="descriptTx" placeholder="Descripción del producto" name="description" {...register("description")} ></textarea>
                </Col>
              </Row>
              {categorySelected === "REFACCIONES" ?
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="brandProd">
                      Marca
                    </Label>
                    <input className="form-control" id="brandProd" type="text" placeholder="Marca" name="brand" {...register("brand")} />
                  </Col>
                </Row>
              : null}
              {categorySelected !== "REFACCIONES" ?
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="rangeProd">
                      Rango
                    </Label>
                    <input className="form-control" id="rangeProd" type="text" placeholder="Rango" name="range" {...register("range")} />
                  </Col>
                </Row>
              : null}
              {categorySelected === "MANOMETROS" ?
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="pedimentPro">
                      Pedimento
                    </Label>
                    <input className="form-control" id="pedimentPro" type="text" placeholder="Pedimento" name="pediment" {...register("pediment")} />
                  </Col>
                </Row>
              : null}
              {isEditingProduct?
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="categoryProd">
                      Imagen
                    </Label>
                    {pictures.length < 1 ?
                    <HydroCapture attach={attach}/> : null}
                    <div className="row">
                      {
                        pictures.map((img,i)=>{
                          return(
                            <div key={i} className="flex justify-start mr-5 col-3">
                              <img className="mb-5 rounded" src={img} alt={"image-" + i} style={{maxWidth:220, maxHeight:220}} />
                              <div className="text-center" style={{background:'red', color:'white', width:30, height:30, paddingRight:10, paddingLeft:10, paddingTop:3, marginLeft:-15, borderRadius:50, cursor:'pointer', top:0, right: 10, position:'absolute'}} onClick={()=>handleDeleteActionPicture(img)}><b>x</b></div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </Col>
                </Row>
                : null }
              <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
              {
                isEditingProduct ? 
                  <Button disabled={patchingProduct} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                : 
                  <Button disabled={savingProduct} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
              }
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalPart} toggle={togglePart}>
        <ModalHeader toggle={togglePart}>Crear Número de Parte
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={togglePart}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="categoryProd">
                    Tipo
                  </Label>
                  <select defaultValue={typeItem} onChange={(e)=>setTypeItem(e.target.value)} className="form-control" name="category" >
                    <option value="PART">Refacción</option>
                  </select>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="llave">
                    Clave
                  </Label>
                  <input className="form-control" value={keyItem} type="text" placeholder="Llave" onChange={(e)=>setKeyItem(e.target.value)} />
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="descriptTx">
                    Descripción
                  </Label>
                  <textarea className="form-control" value={descriptionItem} placeholder="Descripción del número de parte" onChange={(e)=>setDescriptionItem(e.target.value)} ></textarea>
                </Col>
              </Row>
              <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                <Button disabled={savingItem} onClick={saveItem} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button>
              </Row>
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default Inventory;